import {defaultConfig} from '@/api/Configs'

export const quizResult =
{
  setQuizResult(data)
  {
    return fetch(`${defaultConfig.baseURL}/api/quiz-results`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'POST',
        body: JSON.stringify({data: data}),
      });
  },
}
