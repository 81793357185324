<template>
    <DefaultLayout>
      <slot />
    </DefaultLayout>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout.vue'
  export default {
    name: 'home',
    components:
    {
      DefaultLayout
    }
  }
</script>