<template>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | юрдиктант.рф` : `юрдиктант.рф` }}</template>
    </metainfo>
    <the-header
      @translated = 'translated'
      @langSelect = 'langSelect'
      @langsT = 'langsT'
      :langSelectText = 'langSelectText'
    />
    <div class="main">
      <router-view :lang="lang" :langAll = "langAll"/>
    </div>
    <the-footer
      @translated = 'translated'
      @langSelect = 'langSelect'
      @langsT = 'langsT'
      :langSelectText = 'langSelectText'
      :lang="lang"
    />
</template>
<script>
  import { ref } from "vue";
  import TheHeader from '@/components/TheHeader/TheHeader.vue'
  import TheFooter from '@/components/TheFooter/TheFooter.vue'

  export default {
    name: 'default',
    components:
    {
      TheHeader,
      TheFooter
    },
    setup()
    {
      const lang = ref(null);
      const langAll = ref(null);
      const langSelectText = ref('')
      const langSelect = (val)=>
      {
        langSelectText.value = val;
      }
      const langsT = (val)=> langAll.value = val;
      const translated = (val)=> lang.value = val;
      return {
        langsT,
        translated,
        langAll,
        lang,
        langSelect,
        langSelectText
      }
    }
  }
</script>
