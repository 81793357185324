import {baseApi} from '@/api/BaseApi'
export default
{
  state:
  {
    system:null,
    resultCount: 0
  },
  mutations:
  {
    setSystem(state, system)
    {
      state.system = system
    },
    countToResult(state, resultCount)
    {
      state.resultCount = resultCount.meta.pagination.total;
    }
  },
  actions:
  {
    async GET_QUIZ_SISTEM({commit})
    {
      try {
        const response = await baseApi.systemQuiz();
        const responseJson = await response.json();
        commit('setSystem', responseJson)
      } catch(e) {
        console.log(e);
      }
    },
    async GET_QUIZRESULT({commit})
    {
      try {
        const response = await baseApi.getQuizUsers();
        const responseJson = await response.json();
        commit('countToResult', responseJson)
      } catch(e) {
        console.log(e);
      }
    },
  }
}
