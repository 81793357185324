import {aboutUs} from '@/api/AboutUs'
import { ElNotification } from "element-plus";
export default
{
  state:
  {
    news: [],
    singleNews: [],
  },
  mutations:
  {
    setNews(state, news)
    {
      state.news = news;
    },
    setSingleNews(state, news)
    {
      state.singleNews = news;
    },
  },
  actions:
  {
    async GET_NEWS({commit}, lang)
    {
      try {
        const response = await aboutUs.getNews();
        const responseJson = await response.json();
        commit('setNews', responseJson)
      } catch(e) {
       console.log(e);
      }
    },
    async GET_SINGLE_NEWS({commit}, alias)
    {
      try {
        const response = await aboutUs.getSingleNews(alias);
        const responseJson = await response.json();
        // console.log('responseJson', responseJson);
        // console.log('this.state', this.state);
        commit('setSingleNews', responseJson.data[0])
      } catch(e) {
       console.log(e);
      }
    },
  }
}
