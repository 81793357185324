<template>
  <div class="lang-select" v-click-outside = "onClickOutside">
    <div class="lang-select__flex" @click="showSelect">
      <div class="lang-select__name">{{selText.lang || lang}}</div>
      <div class="lang-select__img">
        <img
          :src="selText.img || langImg"
          alt=""
        >
      </div>
      <svg :class="{revert: isShow}" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.975285 1.26879L1.64407 0.600005L5.55649 4.51243L9.46891 0.600006L10.1377 1.2688L5.55649 5.85001L0.975285 1.26879Z" fill="black"/>
      </svg>
    </div>
      <ul class="lang-select__list"
        :class = "{active: isShow}"
      >
        <li
          class="lang-select__item"
          v-for="(lang, index) of langObject"
          :key = "lang.id"
          @click = "changeLang(lang.value, langObject, index, lang)"
          v-show="lang.visible"
        >
            <span>
              {{lang.value.name}}
            </span>
            <img :src="lang.value.img" alt="">
          </li>
      </ul>
    </div>
  <!-- </div> -->
</template>

<script>
  import { ref, computed,watch } from "vue";
  import { useCookies } from "vue3-cookies";
  import { useStore } from "vuex";
  export default {
    props:
    {
      selText:
      {
        type: String,
        default: ''
      },
      // test:
      // {
      //   type: String,
      //   default: ''
      // }
    },
    setup(props, {emit})
    {
      const { cookies } = useCookies();
      const store = useStore();
      const lang = ref('Русский')
      const langCoockies = ref(cookies.get('lang'))
      const isShow = ref(false)
      const langImg = ref(require('@/assets/images/svg/rus.svg'))
      const globalLangObject = computed(()=>store.state.Lang.lang)
      const langObject = ref(
      [
        {
          id: 1,
          lang: 'ru',
          visible:false,
          value:
          {
            name: 'Русский',
            img: require('@/assets/images/svg/rus.svg'),
          },
        },
        {
          id: 2,
          lang: 'zh',
          visible:true,
          value:
          {
            name: 'Китайский',
            img: require('@/assets/images/svg/china.svg'),
          },
        },
        {
          id: 3,
          lang: 'en',
          visible:true,
          value:
          {
            name: 'Английский',
            img: require('@/assets/images/svg/uk.svg'),
          },
        },
        {
          id: 4,
          lang: 'pt-PT',
          visible:true,
          value:
          {
            name: 'Португальский',
            img: require('@/assets/images/svg/porto.svg'),
          },
        },
        {
          id: 5,
          lang: 'es',
          visible:true,
          value:
          {
            name: 'Испанский',
            img: require('@/assets/images/svg/espaniol.svg'),
          },
        },
        {
          id: 6,
          lang: 'ar',
          visible:true,
          value:
          {
            name: 'Арабский',
            img: require('@/assets/images/svg/arab.svg'),
          },
        },
      ])
      const closeSelect = () =>
      {
        isShow.value = false;
      }
      const showSelect = () =>
      {
        isShow.value = !isShow.value;
      }
      // cookies.set('lang', 'ru')
      const changeLang = async (val, object, index, languidge) =>
      {
        cookies.set('lang', languidge.lang)
        // console.log(globalLangObject.value[index]);
        lang.value = val.name;
        langImg.value = val.img;
        object.forEach((el,index)=>
        {
          el.visible = true;
        })
        object[index].visible = false
        emit('langSelect', {lang: lang.value, img: langImg.value})
        emit('langsT', languidge.lang)
        await store.dispatch('GET_DICTIONARY',languidge.lang)
        await store.dispatch('GET_NEWS',languidge.lang)
        // console.log(store.state.Lang.dictionary);
        emit('translated', store.state.Lang.dictionary)
        closeSelect();
      }
      const onClickOutside = () =>
      {
        isShow.value = false;
      }
      return {
        langObject,
        changeLang,
        lang,
        langImg,
        isShow,
        showSelect,
        onClickOutside,
        langCoockies
      }
    }
  }
</script>

<style lang="scss">
  @import "LangSelect";
</style>
