import {defaultConfig} from '@/api/Configs'

export const baseApi =
{
  registerUser(data)
  {
    return fetch(`${defaultConfig.baseURL}/api/quiz-users`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'POST',
        body: JSON.stringify({data: data}),
      });
  },
  systemQuiz()
  {
    return fetch(`${defaultConfig.baseURL}/api/system-quizs`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
  getDictionary(lang)
  {
    return fetch(`${defaultConfig.baseURL}/api/dictionaries?pagination[pageSize]=200&locale=${lang || 'ru'}`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
  getQuestions(lang)
  {
    return fetch(`${defaultConfig.baseURL}/api/questions?pagination[pageSize]=80&[locale]=${lang || 'ru'}`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
  getQuizUserInfo(lang)
  {
    return fetch(`${defaultConfig.baseURL}/api/registration-fields?[locale]=${lang || 'ru'}`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
  getQuizUsers()
  {
    return fetch(`${defaultConfig.baseURL}/api/quiz-users`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
}
