import {defaultConfig} from '@/api/Configs'

export const partners =
{
  getKeyPartners()
  {
    return fetch(`${defaultConfig.baseURL}/api/partners?populate=*&filters[type][$eq]=key`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
  getInformPartners()
  {
    return fetch(`${defaultConfig.baseURL}/api/partners?populate=*&filters[type][$eq]=inform`, {
        headers:
        {
          ...defaultConfig.headers,
        },
        method: 'GET',
      });
  },
}