import {partners} from '@/api/Partners'
import { ElNotification } from "element-plus";
export default
{
  state:
  {
    keyPartners: [],
    informPartners: [],
  },
  mutations:
  {
    setKeyPartners(state, partners)
    {
      state.keyPartners = partners;
    },
    setInformPartners(state, partners)
    {
      state.informPartners = partners;
    },
  },
  actions:
  {
    async GET_KEY_PARTNERS({commit})
    {
      try {
        const response = await partners.getKeyPartners();
        const responseJson = await response.json();
        commit('setKeyPartners', responseJson)
      } catch(e) {
       console.log(e);
      }
    },
    async GET_INFORM_PARTNERS({commit})
    {
      try {
        const response = await partners.getInformPartners();
        const responseJson = await response.json();
        commit('setInformPartners', responseJson)
      } catch(e) {
       console.log(e);
      }
    },
  }
}
