import {baseApi} from '@/api/BaseApi'
import { ElNotification } from "element-plus";
export default
{
  state:
  {
    units: [],
    // userRole: null,
    changeUnit: null,
    info: null,
  },
  mutations:
  {
    setUserInfo(state, info)
    {
      let regular = /\s*;\s*/g;
      let sortArr = []
      info.data.forEach((el,index)=>
      {
        sortArr = el.attributes.options.split(regular).map((item,itemIndex)=>
        {
          return {
            value: item,
            label: itemIndex,
          }
        })
        el.attributes.options = sortArr
      })
      state.info = info.data.map((el)=>
      {
        return {code: el.attributes.code, options: el.attributes.options}
      });
      // if(JSON.stringify(state.info) != JSON.stringify(info))
      //   state.info = info;
    }
  },
  actions:
  {
    async REGISTER_USER({commit}, data)
    {
      try {
        const response = await baseApi.registerUser(data.value);
        const responseJson = await response.json();
      } catch(e) {
        console.log(e);
      }
    },
    async GET_INFO({commit}, lang)
    {
      try {
        const response = await baseApi.getQuizUserInfo(lang);
        const responseJson = await response.json();
        commit('setUserInfo', responseJson)
      } catch(e) {
        console.log(e);
      }
    },
  }
}
