import {baseApi} from '@/api/BaseApi'
export default
{
	state:
	{
		dictionary:[],
		lang:
		[
		{
			code: 'Ru',
			mainBlock:
			{
				title: 'ВСЕРОСИЙСКИЙ ПРАВОВОЙ (ЮРИДИЧЕСКИЙ) ДИКТАНТ',
				abs: 'C 3 по 12 декабря принять участие в написании IV Всероссийского правового (юридического) диктанта и проверить свои познания в юриспруденции.',
				btnName: 'Написать диктант'
			}
		},
		{
			code: 'China',
			mainBlock:
			{
				title: '全俄法律（法律）聽寫',
				abs: '12月3日至12月12日，參加IV全俄法律（法律）口授寫作，測試您的法學知識。',
				btnName: '寫聽寫'
			}
		},
		{
			code: 'Eng',
			mainBlock:
			{
				title: 'ALL-RUSSIAN LEGAL (LEGAL) dictation',
				abs: 'From December 3 to December 12, take part in writing the IV All-Russian legal (legal) dictation and test your knowledge of jurisprudence.',
				btnName: 'Write a dictation'
			}
		},
		{
			code: 'Port',
			mainBlock:
			{
				title: 'Ditado LEGAL TOTALMENTE RUSSO (LEGAL)',
				abs: 'De 3 a 12 de dezembro, participe da redação do IV ditado legal (legal) para toda a Rússia e teste seus conhecimentos de jurisprudência.',
				btnName: 'Escreva um ditado'
			}
		},
		]
	},
	mutations:
	{
		setDictionary(state, dictionary)
		{
			state.dictionary = dictionary
		}
	},
	actions:
	{
		async GET_DICTIONARY({commit},lang)
		{
			try {
				const response = await baseApi.getDictionary(lang);
				const responseJson = await response.json();
				let newJson =responseJson.data.sort(function(a, b){
					if(a.attributes.place < b.attributes.place) { return -1; }
					if(a.attributes.place > b.attributes.place) { return 1; }
						// return 0;
						return newJson.forEach(element => console.log(element));
					})
				// commit('setDictionary', responseJson)
				const newData = {};
				responseJson.data.forEach((item) => {
					newData[item.attributes.place] = item.attributes.value;
				});
				// console.log(newData.information_banner_index);
				commit('setDictionary', newData)
			} catch(e) {
				console.log(e);
			}
		}
	}
}
