import {quizResult} from '@/api/QuizResult'
import { ElNotification } from "element-plus";
export default
{
  state:
  {
  },
  mutations:
  {
  },
  actions:
  {
    async SET_QUIZRESULT({commit}, data)
    {
      try {
        const response = await quizResult.setQuizResult(data);
        const responseJson = await response.json();

      } catch(e) {
        console.log(e);
      }
    }
  }
}
