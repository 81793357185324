import { createSSRApp } from 'vue'
// import express  from 'express'
import VueCookies from 'vue3-cookies'
import { renderToString } from 'vue/server-renderer'
import App from './App'
import "./assets/style/style.scss";
import router from "./router"
import { ElementPlus } from "@/plugins/element-plus";
import vClickOutside from "click-outside-vue3";
import store from '@/store';
import panZoom from 'vue-panzoom';
import VueYandexMetrika from 'vue3-yandex-metrika'
import { createMetaManager } from 'vue-meta'

const app = createSSRApp(App)
// export const createApp = () => {
//   return createSSRApp(App);
// };
app
  .use(VueCookies)
  .use(router)
  .use(VueYandexMetrika ,{
    id: 90868368,
    env: process.env.NODE_ENV
  })
  .use(ElementPlus)
  .use(createMetaManager())
  .use(vClickOutside)
  .use(store)
  .mount('#app')
